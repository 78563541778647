<template>
  <div class="container" style="max-width: 500px">
    <div class="payment-simple" v-if="scriptIsLoaded">
      SCIPT LOADED
      <StripeElements
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
        #default="{ elements }"
        ref="elms"
      >
        <!--  

        <StripeElement type="card" :elements="elements" :options="cardOptions" ref="card" />
        bbbbb -->
        <StripeElement type="payment" :elements="elements" :options="setupElementOption" ref="stripeElementPayment" />
      </StripeElements>
      <button @click="pay" type="button">Pay</button>

      SEE: https://stripe.com/docs/payments/save-and-reuse
    </div>

    <div class="card">
      <div class="card-content">
        <img
          class="avatar"
          :src="user.avatar"
          style="width: 50px; height: 50px; border-radius: 200px; object-fit: cover"
        />

        <div class="media">
          <div class="media-content">
            <div>
              <p class="title is-4">{{ user.name }}</p>
              <p class="subtitle is-6">Refill your wallet</p>
            </div>
          </div>
        </div>

        <div class="content">
          <hr />

          <p><strong>Current Balance: </strong> ${{ walletBalance }}</p>

          <hr />
          <p>
            <strong>Add to Balance: </strong>
            <b-input type="number" v-model.number="addBalance" min="0"></b-input>
          </p>

          <hr />
          <div>
            <strong>Payment method: </strong>
            <b-radio-group v-model="selectedCard">
              <b-radio v-for="card in cards" :key="card.id" :native-value="card.id">
                {{ card.name }} ending in {{ card.last4 }}
              </b-radio>
              <b-radio native-value="new">Use a new card</b-radio>
            </b-radio-group>
          </div>

          <hr />
          <div>
            <b-checkbox v-model="autoRecharge">Enable Auto Recharge</b-checkbox>
            <p class="help">When your balance is low, your card will be automatically charged to refill your wallet.</p>
          </div>

          <b-button type="is-success" @click="confirmRefill" class="is-fullwidth">Add to Wallet</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeElements, StripeElement } from "vue-stripe-elements-plus";

export default {
  data() {
    return {
      scriptIsLoaded: false,
      //
      user: {},
      walletBalance: 0,
      addBalance: 0,
      cards: [],
      selectedCard: null,
      autoRecharge: false,

      //
      stripeKey: "pk_test_PUiiGmZBgPzU4jTuekyz1OPK009k2zlU8t", // test key, don't hardcode
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
        mode: "setup", //not payment, just slightly different..
        currency: "usd",
      },
      cardOptions: {
        // reactive
        // remember about Vue 2 reactivity limitations when dealing with options
        value: {
          postalCode: "",
        },

        // https://stripe.com/docs/stripe.js#element-options
      },
      setupElementOption: {
        //https://stripe.com/docs/payments/payment-element
        layout: {
          type: "accordion", ///or tabs
          defaultCollapsed: false,
          radios: true,
          spacedAccordionItems: false,
        },

        value: {
          postalCode: "",
        },
      },
    };
  },
  components: {
    StripeElements,
    StripeElement,
  },
  created() {
    //loadscript via $loadscitp
    // https://js.stripe.com/v3/

    this.getUserAndWalletInfo();
  },
  computed: {
    userId() {
      return this.$route.query.user;
    },
  },
  beforeMount() {
    this.loadScript("https://js.stripe.com/v3/")
      .then(() => {
        this.scriptIsLoaded = true;
      })
      .catch((error) => {
        console.error(`There was an issue loading the script: ${error}`);
      });
  },
  methods: {
    loadScript(url) {
      return new Promise((resolve, reject) => {
        // Check if the script is already present
        if (document.querySelector(`script[src="${url}"]`)) {
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.async = true;

        script.onload = resolve;
        script.onerror = reject;

        document.head.appendChild(script);
      });
    },
    pay() {
      // ref in template
      const groupComponent = this.$refs.elms;
      const cardComponent = this.$refs.card;
      // Get stripe element
      const cardElement = cardComponent.stripeElement;

      // Access instance methods, e.g. createToken()
      groupComponent.instance.createToken(cardElement).then((result) => {
        // Handle result.error or result.token
      });
    },
    getUserAndWalletInfo() {
      window.API.getUserProfile(this.userId)
        .then((response) => {
          this.user = response;
          this.walletBalance = response.walletBalance;
          this.cards = response.cards;
        })
        .catch((error) => console.error(error));
    },
    confirmRefill() {
      const refillDetails = {
        userId: this.userId,
        addBalance: this.addBalance,
        selectedCard: this.selectedCard,
        autoRecharge: this.autoRecharge,
      };
      window.API.refillWallet(refillDetails)
        .then((response) => {
          this.walletBalance = response.newWalletBalance;
          this.addBalance = 0;
        })
        .catch((error) => console.error(error));
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
}
</style>
